<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { awards } from "@/config/api/awards";
import AwardCompaniesModal from "@/components/awards/award-modal";
import { users } from "@/config/api/users";
/**
 * Award Component
 */
export default {
  page: {
    title: "Companies Awards",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    AwardCompaniesModal,
  },

  data() {
    return {
      title: "Companies Awards",
      items: [
        {
          text: "",
          // to: { name: "" },
        },
        {
          text: "All Companies Awards",
          active: true,
        },
      ],
      companiesData: [],
      awardsData: [],
      selectedAward: null,
      rows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      activeTab: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "image", sortable: false, label: "Award" },
        { key: "name", sortable: false, label: "Award Name" },
        { key: "date_received", sortable: false, label: "Received On" },
        { key: "is_verified", sortable: false, label: "Verified" },
        { key: "createdAt", sortable: false },
      ],
    };
  },
  async beforeMount() {
    await this.loadData();
    await this.loadCompanyData();
  },

  mounted() {
    // Set the initial number of items
    // this.totalRows = this.awardsData.length;
  },
  methods: {
    updatePage(page) {
      this.loadData(page);
    },
    changePage(val) {
      this.currentPage = val;
      this.loadData();
    },
    async loadData() {
      try {
        const api = awards.companies.getAll;
        api.params = { page: this.currentPage, limit: this.perPage };
        let res = await this.generateAPI(api);
        this.rows = res.data.models.totalDocs;
        this.awardsData = res.data.models.docs;
      } catch (error) {
        console.error(error);
      }
    },
    async loadCompanyData() {
      try {
        const api = users.getAll;
        api.params = { limit: 9999999, usersOnly: true, isCompany: true };
        let res = await this.generateAPI(api);
        res.data.companies.docs.forEach((element) => {
          this.companiesData.push({
            label: element.legal_name
              ? element.legal_name
              : `no name - id(${element._id})`,
            value: element._id,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },

    showActionModal() {
      this.$bvModal.show("action-Award");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Award");
    },
    editAward(data) {
      this.selectedAward = data;
      this.showActionModal();
    },
    navigate(path, item) {
      this.$router.push({
        path: path,
        query: { id: item._id },
      });
      
      this.$store.dispatch("awards/storeAwards", item);
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/awards-company-info",
        query: { id: item._id },
      });
    },
    perPageChange(val) {
      this.currentPage = 1;
      this.perPage = val;
      this.loadData();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button
              id="addAward"
              @click="showActionModal"
              pill
              variant="primary"
              >Add Award</b-button
            >
            <div class="clearfix"></div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <!-- <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label> -->
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="table-responsive">
              <b-table
                tbody-tr-class="rowClass"
                class="table-centered"
                :items="awardsData"
                :fields="fields"
                responsive="sm"
                :busy="$store.state.api.loading"
                :per-page="perPage"
                @row-clicked="onRowClicked"
                show-empty
              >
                <template #empty>
                  <div class="text-center">No award found!</div>
                </template>
                <template #table-busy>
                  <div style="text-align: center" class="mt-5 mb-5">
                    <pulse-loader
                      color="#505d69"
                      :loading="true"
                    ></pulse-loader>
                  </div>
                </template>
                <template v-slot:cell(image)="row">
                  <b-img
                    thumbnail
                    class="award-image"
                    :src="require('@/assets/award.png')"
                  ></b-img>
                  <div class="text-center" v-if="row.item.deletedAt">
                    <b-badge variant="danger">Deleted</b-badge>
                  </div>
                </template>
                <template v-slot:cell(name)="row">
                  {{ row.item.name }}
                </template>
                <template v-slot:cell(date_received)="row">
                  {{
                    !row.item.date_received
                      ? ""
                      : moment(row.item.date_received).format("l")
                  }}
                </template>
                <template v-slot:cell(is_verified)="row">
                  {{ row.item.is_verified ? "Yes" : "No" }}
                </template>

                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("l") }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      :value="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="changePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AwardCompaniesModal
      :selectedAward="selectedAward"
      :awardData="companiesData"
      v-on:resetModal="selectedAward = null"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
      type="company"
    />
  </Layout>
</template>

<style scoped>
#addAward {
  float: right;
  margin-top: 10px;
}
.award-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
</style>
